/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "@quipuswap/ui-kit/dist/ui-kit.cjs.development.css";

import { Buffer } from "buffer";
import Modal from "react-modal";

Object.assign(window, { Buffer });
Modal.setAppElement(document.body);
